<template>
  <div>
    <v-row>
      <v-col cols="12">
        <order-list :has-title="$t('ecommerce.orders.dashboard.processing.title')"
                    :has-filters="false"
                    :has-search="false"
                    :has-report-download="false"
                    :statuses="['processing']"
        ></order-list>
      </v-col>
    </v-row>

    <v-row v-if="isStoreUser || isCcUser">
      <v-col cols="12">
        <order-list :has-title="$t('ecommerce.orders.dashboard.on_hold.title')"
                    :has-filters="false"
                    :has-search="false"
                    :has-report-download="false"
                    :statuses="['on_hold']"
        ></order-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import List from "@/components/ecommerce/List";

export default {
  name: "Orders",

  components: {
    'order-list': List
  },

  computed: {
    ...mapGetters({
      isStoreUser: 'auth/isStoreUser',
      isCcUser: 'auth/isCcUser'
    }),
  }
}
</script>

<style scoped>

</style>