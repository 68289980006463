<template>

  <v-sheet>

    <orders v-if="isCcUser || isStoreUser"></orders>

  </v-sheet>

</template>

<script>

  import Orders from "@/components/dashboard/Orders";
  import {mapGetters} from "vuex";

    export default {
        name: "Dashboard",

      components: {
          Orders
      },

      computed: {
        ...mapGetters({
          isStoreUser: 'auth/isStoreUser',
          isCcUser: 'auth/isCcUser'
        }),
      }
    }
</script>

<style scoped>

</style>
